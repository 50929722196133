var exports = {};
var module = {
  exports: exports
};
(function (a, b) {
  a.tinysort = b;
})(window || module || {}, function (a) {
  function c(a, b) {
    for (var c, d = a.length, e = d; e--;) c = d - e - 1, b(a[c], c);
    return a;
  }
  function b(a, b, c) {
    for (var d in b) (c || a[d] === e) && (a[d] = b[d]);
    return a;
  }
  function d(a, b, c) {
    return l.push({
      prepare: a,
      sort: b,
      sortBy: c
    });
  }
  var e = a,
    f = null,
    g = window,
    h = g.document,
    i = parseFloat,
    j = /(-?\d+\.?\d*)\s*$/g,
    k = /(\d+\.?\d*)\s*$/g,
    l = [],
    m = function (a) {
      return String.fromCharCode(a);
    },
    n = function (a) {
      return Array.from([,,,], function (b, c) {
        return m(a + c);
      });
    },
    o = n(0),
    p = n(4095),
    q = {
      selector: f,
      order: "asc",
      attr: f,
      data: f,
      useVal: !1,
      place: "org",
      returns: !1,
      cases: !1,
      natural: !1,
      forceStrings: !1,
      ignoreDashes: !1,
      sortFunction: f,
      useFlex: !1,
      emptyEnd: !1,
      console: console
    },
    r = 0,
    s = 0;
  return g.Element && function (a) {
    return a.matches = a.matches || a.msMatchesSelector;
  }(Element.prototype), b(d, {
    loop: c
  }), b(function (a) {
    for (var A = arguments.length, B = Array(1 < A ? A - 1 : 0), C = 1; C < A; C++) B[C - 1] = arguments[C];
    function d(a) {
      var c = !!a.selector,
        d = c && ":" === a.selector[0],
        e = b(a || {}, q);
      return L.push(b({
        hasSelector: c,
        hasAttr: e.attr !== f && "" !== e.attr,
        hasData: e.data !== f,
        hasFilter: d,
        sortReturnNumber: "asc" === e.order ? 1 : -1
      }, e));
    }
    function g() {
      c(a, function (a, b) {
        M ? M !== a.parentNode && (N = !1) : M = a.parentNode;
        var c = L[0],
          d = c.hasFilter,
          e = c.selector,
          f = !e || d && a.matches(e) || e && a.querySelector(e),
          g = f ? I : J,
          h = g.length,
          i = {
            elm: a,
            pos: b,
            posn: h
          };
        H.push(i), g.push(i);
      }), K.splice.apply(K, [0, Number.MAX_SAFE_INTEGER].concat(I));
    }
    function m(e, a, b) {
      for (var f = b(e.toString()), g = b(a.toString()), h = 0; f[h] && g[h]; h++) if (f[h] !== g[h]) {
        var i = +f[h],
          c = +g[h];
        return i == f[h] && c == g[h] ? i - c : f[h] > g[h] ? 1 : -1;
      }
      return f.length - g.length;
    }
    function n(a) {
      for (var b, c = [], d = 0, e = -1, f = 0, g = void 0, h = void 0; g = (h = a.charAt(d++)).charCodeAt(0);) b = 46 === g || 48 <= g && 57 >= g, b !== f && (c[++e] = "", f = b), c[e] += h;
      return c;
    }
    function t(d, a) {
      var b = 0;
      0 !== s && (s = 0);
      for (var e = function () {
        var e = L[s],
          f = e.ignoreDashes ? k : j;
        c(l, function (a) {
          return a.prepare && a.prepare(e);
        });
        var g = !1,
          h = y(d, e),
          o = y(a, e);
        if (e.sortFunction) b = e.sortFunction(d, a);else if ("rand" === e.order) b = 0.5 > Math.random() ? 1 : -1;else if (h === o) b = 0;else {
          if (!e.forceStrings) {
            var p = !!z(h) && h && h.match(f),
              q = !!z(o) && o && o.match(f);
            if (p && q) {
              var r = h.substr(0, h.length - p[0].length),
                t = o.substr(0, o.length - q[0].length);
              r == t && (g = !0, h = i(p[0]), o = i(q[0]));
            }
          }
          b = e.natural && (isNaN(h) || isNaN(o)) ? m(h, o, n) : h < o ? -1 : h > o ? 1 : 0;
        }
        c(l, function (a) {
          var c = a.sort;
          return c && (b = c(e, g, h, o, b));
        }), b *= e.sortReturnNumber, 0 === b && s++;
      }; 0 === b && s < r;) e();
      return 0 === b && (b = d.pos > a.pos ? 1 : -1), b;
    }
    function u() {
      var a = I.length,
        b = a === H.length,
        c = a === M.children.length,
        d = L[0],
        e = d.place,
        f = d.console;
      if (N && b && c) Q ? I.forEach(function (a, b) {
        return a.elm.style.order = b;
      }) : M ? M.appendChild(v()) : f && f.warn && f.warn("parentNode has been removed");else {
        var g = "start" === e,
          h = "end" === e,
          i = "first" === e;
        if ("org" === e) I.forEach(w), I.forEach(function (a, b) {
          return x(K[b], a.elm);
        });else if (g || h) {
          var j = K[g ? 0 : K.length - 1],
            k = j && j.elm.parentNode,
            l = k && (g && k.firstChild || k.lastChild);
          l && (l !== j.elm && (j = {
            elm: l
          }), w(j), h && k.appendChild(j.ghost), x(j, v()));
        } else if (i || "last" === e) {
          var m = K[i ? 0 : K.length - 1];
          x(w(m), v());
        }
      }
    }
    function v() {
      return I.forEach(function (a) {
        return G.appendChild(a.elm);
      }), G;
    }
    function w(a) {
      var b = a.elm,
        c = h.createElement("div");
      return a.ghost = c, b.parentNode.insertBefore(c, b), a;
    }
    function x(a, b) {
      var c = a.ghost,
        d = c.parentNode;
      d.insertBefore(b, c), d.removeChild(c), delete a.ghost;
    }
    function y(a, b) {
      var c,
        d = a.elm,
        g = b.selector;
      g && (b.hasFilter ? !d.matches(g) && (d = f) : d = d.querySelector(g)), b.hasAttr ? c = d.getAttribute(b.attr) : b.useVal ? c = d.value || d.getAttribute("value") : b.hasData ? c = d.getAttribute("data-" + b.data) : d && (c = d.textContent), z(c) && (!b.cases && (c = c.toLowerCase()), c = c.replace(/\s+/g, " "));
      var h = [e, f, ""].indexOf(c);
      return -1 !== h && (c = (b.emptyEnd ? p : o)[h]), c;
    }
    function z(a) {
      return "string" == typeof a;
    }
    var D = B[0] || {};
    z(a) && (a = h.querySelectorAll(a));
    var E = Object.assign({}, q, D || {}),
      F = E.console;
    0 === a.length && F && F.warn && F.warn("No elements to sort");
    var G = h.createDocumentFragment(),
      H = [],
      I = [],
      J = [],
      K = [],
      L = [],
      M = void 0,
      N = !0,
      O = a.length && a[0].parentNode,
      P = O.rootNode !== document,
      Q = a.length && (D === e || !1 !== D.useFlex) && !P && -1 !== getComputedStyle(O, null).display.indexOf("flex");
    return r = function (a) {
      return 0 === a.length && d({}) || c(a, function (a) {
        return d(z(a) ? {
          selector: a
        } : a);
      }).length;
    }(B), g(), I.sort(D.sortFunction || t), u(), I.map(function (a) {
      return a.elm;
    });
  }, {
    plugin: d,
    defaults: q
  });
}());
export default module.exports;